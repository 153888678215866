import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../Router/Path";
import Logo from "../../Static/Vizzion_logo.png";

export default function Header() {
  return (
    <>
      <div className="Header">
        <section className="Logo">
          <Link to={PATHS.HOME}>
            <img src={Logo} alt="Logo Vizzion" />
          </Link>
        </section>
        <section className="links">
          <ul className="listLinks">
            <li>
              <Link to={PATHS.HOME}>Home</Link>
            </li>
            {/*
            <li>
              <Link to={PATHS.SOLUCAO}>Soluções</Link>
            </li>
            */}
            <li>
              <Link to={PATHS.CONTATO}>Contato</Link>
            </li>
            {/*
            <li>
              <Link to={PATHS.SOBRE}>Sobre</Link>
            </li>
            <li>
              <Link to={PATHS.TRABALHE_CONOSCO}>Trabalhe Conosco</Link>
            </li>
            */}
          </ul>
        </section>
      </div>
    </>
  );
}
