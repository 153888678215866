import React from "react";
import Header from "../../Componentes/Header/Header";
import Banner from "../../Componentes/Banner/HeaderBanner";
import SecaoSolucoes from "../../Componentes/SecaoSolucoes/SecaoSolucoes";
import TechsSecao from "../../Componentes/TechsSecao/TechsSecao";
import SintaxeEspacialSecao from "../../Componentes/SintaxeEspacialSecao/SintaxeEspacialSecao";
import Parceirias from "../../Componentes/Parceirias/Parceirias";
import TrabalheConoscoSecao from "../../Componentes/TrabalheConoscoSecao/TrabalheConoscoSecao";
import Footer from "../../Componentes/Footer/Footer";

export default function Home() {
  return (
    <div className="home">
      <Header />
      <div className="BodyHome">
        <Banner />
        <SecaoSolucoes />
        <TechsSecao />
        <SintaxeEspacialSecao />
        <Parceirias />
        <TrabalheConoscoSecao /> 
        <Footer />
      </div>
    </div>
  );
}
