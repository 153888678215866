import React from "react";
import dot from "../../Static/Polygon 1.svg";

export default function Box() {
  return (
    <div className="Box">
      <div className="container">
        <div className="background" />
        <div className="header">
          <div className="title">Demandas atendidas</div>
        </div>
        <div className="content-left">
          <div className="item">
            <img src={dot} alt="dot" />
            Monitoramento do espaço urbano
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Analise Vocacional do local
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Análise de prontos de desastres naturais
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Previsibilidade de demanda
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Estudo de viabilidade e impacto avançado
          </div>
        </div>
        <div className="content-right">
          <div className="item">
            <img src={dot} alt="dot" />
            Valorização de projetos
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Planejamento urbano
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Mobilidade urbana
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Sustentabilidade e meio ambiente
          </div>
          <div className="item">
            <img src={dot} alt="dot" />
            Gestão da infraestrutura
          </div>
        </div>
      </div>
    </div>
  );
}
