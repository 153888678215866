import React from "react";
import CreaSC from "../../Static/LogoParceiros/Crea-logo.png"
import Almah from "../../Static/LogoParceiros/Almah.png"
import Brognoli from "../../Static/LogoParceiros/Brognoli.png"
import Locates from "../../Static/LogoParceiros/Locates.png"

export default function Parceirias() {
  return ( 
    <div className="Parceirias">
      <div className="containerParceirias">
        <div className="background" />
        <div className="header-background" />
        <div className="header-text">Parceiros estratégicos</div>
        <div className="partner-text">Conheça nossos parceiros:</div>
        <section className="imagesParceirias">
          <img
            className="img"
            src={CreaSC}
            alt="Parceiro 1"
          />
          <img
            className="img"
            src={Brognoli}
            alt="Parceiro 2"
          />
          <img
            className="img"
            src={Almah}
            alt="Parceiro 3"
          />
          <img
            className="img"
            src={Locates}
            alt="Parceiro 4"
          />
        </section>
      </div>
    </div>
  );
}
