import RouterController from "./Router/Router";

function App() {
  return (
    <>
      <RouterController />
    </>
  );
}

export default App;
