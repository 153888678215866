import React from 'react';
import Logo from '../../Static/Vizzion_logo.png';
import NotFoundImage from '../../Static/erreur-404.png';
import { Link } from 'react-router-dom';
import { PATHS } from '../../Router/Path';

export default function NotFound() {
  return (
    <div className="IsNotVerified">
      <img src={Logo} alt="Logo" />
      <section className="DocsText">
        <img src={NotFoundImage} alt="isNotVerifiedd" />
        <div className="Textos">
          <h1>
           Pagina não encontrada
          </h1>
          <p>
            Este caminho não existe, clique no botão abaixo e retorne para a Home!
          </p>

          <Link className="ReturnHome" to={PATHS.HOME}>
            Retornar para Home
          </Link>
        </div>
      </section>
    </div>
  )
}
