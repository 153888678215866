import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../Router/Path";
import dot from "../../Static/Polygon 1.svg";
import Acate from "../../Static/image 10.png"
import Background from "../../Static/backgroundTechSecao.svg";
import Insta from "../../Static/RedesSociais/instagram 1.png";
import Face from "../../Static/RedesSociais/facebook 1.png";
import Linkedin from "../../Static/RedesSociais/linkedin 1.png";
import Blog from "../../Static/RedesSociais/blogue 1.png"

export default function Footer() {
  return (
    <div className="FooterSection">
      <div className="page">
        <div className="background-dark" />
        <img
          className="background-image"
          src={Background}
          alt="Background"
        />

        <div className="join-us">
          <div className="cta-background" />
          <div className="title">Faça parte do nosso time</div>
          <div className="description">
            Buscamos talentos inquietos e apaixonados por tecnologia que queiram
            transformar a forma como as cidades são planejadas.
          </div>
          <div className="cta-text">Banco de talentos</div>
        </div>

        <div className="useful-links">
          <div className="title">Links úteis</div>
          <div className="links">
            <div className="left">
              <span>
                <img src={dot} alt="dot" />
                <Link to={PATHS.HOME}>Home</Link>
              </span>
              <span>
                <img src={dot} alt="dot" />
                <Link to={PATHS.CONTATO}>Contato</Link>
              </span>
              <span>
                <img src={dot} alt="dot" />
                <Link to={PATHS.SOBRE}>Sobre</Link>
              </span>
              <span>
                <img src={dot} alt="dot" />
                <Link to={PATHS.BANCO_DE_TALENTOS}>Trabalhe conosco</Link>
              </span>
              <span>
                <img src={dot} alt="dot" />
                <Link to={PATHS.SOLUCAO}>Soluções</Link>
              </span>
            </div>
          </div>
        </div>

        <div className="social-media">
          <div className="title">Redes Sociais</div>
          <div className="icons">
            <img src={Insta} alt="Social 1" />
            <img src={Face} alt="Social 2" />
            <img src={Linkedin} alt="Social 3" />
            <img src={Blog} alt="Social 4" />
          </div>
        </div>

        <img
          className="image-rounded"
          src={Acate}
          alt="Acate parceiros"
        />
      </div>
    </div>
  );
}
