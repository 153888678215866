import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PATHS } from "./Path";
import SmartTimesForCitties from "../Pages/SmartTimesForCitties/SmartTimesForCitties";
import Home from "../Pages/Home/Home";
import Solucao from "../Pages/Solucao/Solucao";
import Contato from "../Pages/Contato/Contato";
import Parceiros from "../Pages/Parceiros/Parceiros";
import SobreAVizzion from "../Pages/SobreAVizzion/SobreAVizzion";
import TrabalheConosco from "../Pages/Trabalheconosco/TrabalheConosco";
import NotFound from "../Pages/NotFound/NotFound";

export default function RouterController() {
  return (
      <Router>
        <Routes>
          <Route
            path={PATHS.SMART_TIME_FOR_CITTIES}
            element={<SmartTimesForCitties />}
          />
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.SOLUCAO} element={<Solucao />} />
          <Route path={PATHS.CONTATO} element={<Contato />} />
          <Route path={PATHS.PARCEIROS} element={<Parceiros />} />
          <Route path={PATHS.SOBRE} element={<SobreAVizzion />} />
          <Route path={PATHS.TRABALHE_CONOSCO} element={<TrabalheConosco />} />
          <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
        </Routes>
      </Router>
  );
}
