import React from "react";
import logo from "../../Static/Vizzion_logo.png";
import ProductCard from "../ProductCard/ProductCard";
import IconeHouse from "../../Static/dnaHouseIcon.svg";
import imgDnaHouse from "../../Static/Rectangle 11.svg";
import imgDnaTerrain from "../../Static/Rectangle 11-1.svg";
import IconeTerrain from "../../Static/terreno 1.svg";
import imgCityFullMap from "../../Static/Rectangle 11-2.svg";
import iconeCityFullMap from "../../Static/cityFullMapIcon.svg";
import imgLateralSolucao from "../../Static/Victoria Station - Space Syntax 1.svg";
import Box from "../Box/Box";
import vector1 from "../../Static/Vector 1.svg";
import vector2 from "../../Static/Vector 2.svg";


export default function SecaoSolucoes() {
  return (
    <div className="SecaoSolucoes">
      <div className="SolucoesTittle_text">
        <div className="title">
          <h2>Soluções</h2>
          <img src={logo} alt="LogoSubtitle" />
        </div>
        <div className="subtitle">
          <p>
            Conheça as Vizzion que transformarão o planejamento e a tomada de
            decisões em construções e cidades, proporcionando uma visão mais
            clara e estratégica para o futuro.
          </p>
        </div>
      </div>
      <div className="Main">
        <section className="Products">
          <ProductCard
            imgFundo={imgDnaHouse}
            icone={IconeHouse}
            Title="DNA House"
            descricao="Tenha segurança para negociar o imóvel com todas as informações que você precisa"
            SecaoDeDados="DADOS JURÍDICOS | DADOS CARTORÁRIOS | RESTRIÇÕES  POTENCIALIDADES | ANALISE DE ENTORNO | ENTRE OUTROS"
          />
          <ProductCard
            imgFundo={imgDnaTerrain}
            icone={IconeTerrain}
            Title="DNA Terrain"
            descricao="Tudo do house, mais análises exclusivas para terrenos, visanodo o melhor retorno e integração"
            SecaoDeDados="DADOS JURÍDICOS | DADOS CARTORÁRIOS | RESTRIÇÕES  POTENCIALIDADES | ANALISE DE ENTORNO | VOCACIONAL CONSTRUTIVO | ENTRE OUTROS"
          />
          <ProductCard
            imgFundo={imgCityFullMap}
            icone={iconeCityFullMap}
            Title="City full map"
            descricao="Plataforma de análise urbana com alta tecnologia potencializando planejamento urbano"
            SecaoDeDados="DADOS JURÍDICOS | DADOS CARTORÁRIOS | ANALISE DE ENTORNO | PREDIÇÃO DE CRECIMENTO | ANALISE RE REGULARIDADE | ENTRE OUTROS"
          />
        </section>
        <section className="assideHol">
          <img
            src={imgLateralSolucao}
            alt="imagemlateralSolucao"
            className="ImagemLateralSolucao"
          />
          <Box />
        </section>
      </div>
      <span className="vetores">
        <img src={vector1} alt="" />
        <img src={vector2} alt="" />
      </span>
    </div>
  );
}
