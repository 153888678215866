import React from "react";
import backgroundTechSecao from "../../Static/backgroundTechSecao.svg";
import sateliteImage from "../../Static/Satelite.svg";
import ia_icon from "../../Static/inteligencia-artificial.svg";
import BigData_Icon from "../../Static/big-data 1.svg";
import gis_rnc_icon from "../../Static/no-mundo-todo 1.svg";
import ig_icon from "../../Static/mapa 1.svg";
import map_satelite from "../../Static/satelite_icone.svg";
import sintaxe_espacial from "../../Static/mapa-das-ruas 1.svg";
import imageForTech from "../../Static/Rectangle 17.svg";
import imageForTechSintaxe from "../../Static/image 5.svg";
import BoxTech from "../boxTech/BoxTech";

export default function TechsSecao() {
  return (
    <div className="techSecao">
      <div className="Title">
        <div className="headersec">
          <h1>Tecnologia</h1>
          <p>
            Utilizamos a alta tecnologia para trazer insights e analises
            precisas e qualificadas sobre as unidades e cidades analisadas.
          </p>
        </div>
        <img src={sateliteImage} alt="sateliteImage" className="satelite" />
      </div>
      <div className="main">
        <section className="text">
            <div className="item">
                <img src={ia_icon} alt="icone_ia" />
                <p>Inteligência Artificial</p>
            </div>
            <div className="item">
                <img src={gis_rnc_icon} alt="icone_ia" />
                <p>GIS e RNCs</p>
            </div>
            <div className="item">
                <img src={map_satelite} alt="icone_ia" />
                <p>Mapeamento via satélite diário de alta precisão</p>
            </div>
            <div className="item">
                <img src={sintaxe_espacial} alt="icone_ia" />
                <p>Sintaxe Espacial</p>
            </div>
            <div className="item">
                <img src={ig_icon} alt="icone_ia" />
                <p>Inteligência Geografica</p>
            </div>
            <div className="item">
                <img src={BigData_Icon} alt="icone_ia" />
                <p>BigData</p>
            </div>
        </section>
        <section className="images">
            <img src={imageForTech} alt="imageForTech" className="imageFortechSect" />
            <img src={imageForTechSintaxe} alt="imageForTech" className="imageFortechSectSintaxe" />
            <BoxTech />
        </section>
      </div>
      <img src={backgroundTechSecao} alt="" className="backgroundTechSecao" />
    </div>
  );
}
