import React from "react";
import UndrawImage from "../../Static/UndrawImage.png";
import { Link } from "react-router-dom";
import { PATHS } from "../../Router/Path";

export default function TrabalheConoscoSecao() {
  return (
    <div className="TrabalheConoscoSecao">
      <div className="Text">
        <h1>Faça parte do nosso time</h1>
        <p>
          Buscamos talentos inquietos e apaixonados por tecnologia que queiram
          transformar a forma como as cidades são planejadas.
        </p>
        <Link to={PATHS.BANCO_DE_TALENTOS}>BANCO DE TALENTOS</Link>
      </div>
      <img src={UndrawImage} alt="team" className="TeamImage" />
    </div>
  );
}
