import React from "react";

export default function ProductCard({
  imgFundo,
  icone,
  Title,
  descricao,
  SecaoDeDados,
}) {
  return (
    <div className="ProductCardBody">
      <div className="container">
        <div className="box" />
        <div className="highlight" />
        <div className="banner" />
        <div className="title">{Title}</div>
        <div className="description">{descricao}</div>
        <div className="details">{SecaoDeDados}</div>
        <img className="main-image" src={imgFundo} alt="main-imagem" />
        <div className="overlay" />
        <img className="icon" src={icone} alt="icone" />
      </div>
    </div>
  );
}
