export const PATHS = {
    HOME: "/",
    SOLUCAO: "/sobre-a-solucao-vizzion",
    CONTATO: "/contato",
    SOBRE: "/sobre",
    PARCEIROS: "/parceiros",
    TRABALHE_CONOSCO: "/trabalhe-conosco",
    SMART_TIME_FOR_CITTIES: "/smart-vizzion-for-smart-citties",
    BANCO_DE_TALENTOS: "/banco-de-talentos",
    NOT_FOUND: "*"
}