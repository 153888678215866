import React from "react";
import printUsosDoSoloESintaxeVizzion from "../../Static/real SpaceSyntax 1.svg";
import SpaceSyntaxBr from "../../Static/image 3.svg";
import vetor1 from "../../Static/VetorBanner01.png";
import vetor2 from "../../Static/VetorBanner02.png";
import SatelitePreto_Icon from "../../Static/SatelitePreto.png";
import { PATHS } from "../../Router/Path";
import { Link } from "react-router-dom";
import bannerImage from "../../Static/CityBannerImage.svg";


export default function Banner() {
  return (
    <div>
      <section className="headerBanner">
        <div className="BannerHolder">
          <div className="Banner">
            <span className="Vetores">
              <img src={vetor1} alt="Vetor1" className="VetorUm" />
              <img src={vetor2} alt="Vetor2" className="VetorDois" />
            </span>
            <img src={bannerImage} alt="Banner" className="BannerUpperImage" />
          </div>
          <div className="TextSection">
            <section className="TextAndImage">
              <div className="Text">
                <span className="smartCities">Smart Cities</span>
                <h1>Alta Tecnologia aplicada ao planejamento urbano</h1>
                <Link to={PATHS.FALE_COM_UM_ESPECIALISTA}>
                  Fale com um especialista
                </Link>
              </div>
              <div className="images">
                <img
                  src={printUsosDoSoloESintaxeVizzion}
                  alt="Tipos de uso do solo, city full map"
                  className="CitiyFullMapPrint"
                />
                <img
                  src={SpaceSyntaxBr}
                  alt="Sintaxe Espacial Brasileira"
                  className="SpaceSyntaxPrint"
                />
              </div>
            </section>
            <section className="techsAndBox">
              <span className="TechsUtilizadas">
                Sintaxe Espacial | SIG | Inteligência Artificial | Big Data |
                Inteligência Geográfica
              </span>
              <div className="MissaoVizzion">
                <img src={SatelitePreto_Icon} alt="iconeDeSatelite" />
                <p>
                  Reduzimos a irregularidade e ineficiência no planejamento
                  urbano, oferecendo análises imobiliárias precisas e baseadas
                  em dados
                </p>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
