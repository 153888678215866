import React from "react";
import Floripa from "../../Static/CidadesMapeadas/Floripa.png";
import Blumenau from "../../Static/CidadesMapeadas/Blumenau.png";
import Chapeco from "../../Static/CidadesMapeadas/Chapeco.png";
import JaraguaDoSul from "../../Static/CidadesMapeadas/JaraguaDoSul.png";
import Itajai from "../../Static/CidadesMapeadas/Itajai.png";
import Lages from "../../Static/CidadesMapeadas/Lages.png";
import Criciuma from "../../Static/CidadesMapeadas/CriciumaIcara.png";
import Joinvile from "../../Static/CidadesMapeadas/Joinvile.png";

export default function CidadesMapeadas() {
  return (
    <div className="CidadesMapeadas">
      <div className="container">
        <div className="city-card">
          <img className="city-image" src={Floripa} alt="Floripa" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Florianópolis</div>
          </div>
        </div>

        <div className="city-card">
          <img className="city-image" src={Blumenau} alt="Blumenau" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Blumenau</div>
          </div>
        </div>

        <div className="city-card">
          <img className="city-image" src={Chapeco} alt="Chapeco" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Chapecó</div>
          </div>
        </div>
        <div className="city-card">
          <img className="city-image" src={JaraguaDoSul} alt="JaraguaDoSul" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Jaraguá do Sul</div>
          </div>
        </div>
        <div className="city-card">
          <img className="city-image" src={Itajai} alt="Itajai" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Itajaí</div>
          </div>
        </div>
        <div className="city-card">
          <img className="city-image" src={Lages} alt="Lages" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Lages</div>
          </div>
        </div>
        <div className="city-card">
          <img className="city-image" src={Criciuma} alt="CriciumaEIcara" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Criciúma e Içara</div>
          </div>
        </div>

        <div className="city-card">
          <img className="city-image" src={Joinvile} alt="Joinvile" />
          <div className="city-label-wrapper">
            <div className="city-label-bg" />
            <div className="city-label">Joinville</div>
          </div>
        </div>

        <div className="border-box" />
        <div className="header" />
        <div className="header-title">Cidades que já mapeamos</div>
        
      </div>
    </div>
  );
}
