import React from "react";

export default function BoxTech() {
  return (
    <div className="BoxTech">
      <div className="container">
        <div className="background"></div>
        <div className="textBoxTech">análises precisas, responsivas e confiáveis</div>
      </div>
    </div>
  );
}
