import React from "react";
import imagemUm from "../../Static/SintaxeANivelDeImovel_Image_um.svg";
import imagemDois from "../../Static/SintaxeANivelDeImovel_mapa_dois.svg";

export default function SintaxeANivelDeImovel() {
  return (
    <div className="SintaxeANivelDeImovel">
      <div className="container">
        <div className="border-box" />
        <img className="small-image" src={imagemUm} alt="images" />
        <img className="large-image" src={imagemDois} alt="images" />
        <div className="header" />
        <div className="title">Sintaxe a nível de imóvel</div>
      </div>
    </div>
  );
}
