import React from "react";
import SintaxeANivelDeImovel from "../SintaxeANivelDeImovel/SintaxeANivelDeImovel";
import CidadesMapeadas from "../cidadesMapeadas/CidadesMapeadas";
import VetorBG_um from "../../Static/Vetor_sintaxe.svg";
export default function SintaxeEspacialSecao() {
  return (
    <div className="SintaxeEspacialSecao">
      <section className="Main_SintaxeEspacialSecao">
        <div className="textSintaxeEspacial">
          <h2>
            Sintaxe <br /> espacial
          </h2>
          <p>
            A análise da sintaxe espacial, ao quantificar e qualificar as
            relações espaciais entre os elementos urbanos, oferece insights
            valiosos para o mercado imobiliário e o planejamento urbano.
          </p>
          <span className="SelectedArea">
            <p>
              Ao modelar a acessibilidade, a conectividade e a legibilidade
              espacial, essa abordagem permite prever a demanda por diferentes
              tipos de imóveis, otimizar o layout urbano e avaliar o impacto de
              novos empreendimentos no contexto urbano. Os dados gerados pela
              sintaxe espacial são fundamentais para a criação de um
              planejamento urbano estratégico e sustentável, que atenda às
              necessidades da população e promova o desenvolvimento urbano
              equilibrado.
            </p>
          </span>
        </div>
        <div className="ImagesAreasMapeadas">
            <SintaxeANivelDeImovel />
            <CidadesMapeadas />
        </div>
      </section>
      <div className="BackgroundVetores">
        <img src={VetorBG_um} alt="vetor SintaxeUm" />
      </div>
    </div>
  );
}
